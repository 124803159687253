import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        padding: `${theme.spacing(4)}px ${theme.spacing(7)}px `,

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            textAlign: 'center',
            borderRadius: 0,
        },
    },
});

const ProfileClientReviewsEmpty = ({ classes, name }) => {
    return (
        <Paper className={classes.root}>
            No reviews yet for <b>{ name }</b>.
        </Paper>
    );
};

export default withStyles(styles)(ProfileClientReviewsEmpty);
