import React, { useState, useEffect } from "react";
import qs from "query-string";
import {
  REVIEW_TYPE,
  PERSISTED_FIRM_REVIEW_FILTERS,
  FIRM_TYPES,
} from "../../../../__constants__";
import { getServiceLabelBy } from "../../../../data/attributeHelper";
import * as analytics from "../../../../analytics";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Hidden from "@material-ui/core/Hidden";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SelectChip from "../../../FormComponents/SelectChip";

export const stringifyQuery = (query) =>
  qs.stringify(query, { arrayFormat: "bracket" });

export const parseQuery = (queryString) =>
  qs.parse(queryString, { arrayFormat: "bracket" });

const defaultProps = {
  showChipsOnly: false,
};

const AGES = [
  {
    label: "Any age",
  },
  {
    label: "18-34",
    min: 18,
    max: 34,
  },
  {
    label: "35-44",
    min: 35,
    max: 44,
  },
  {
    label: "45-54",
    min: 45,
    max: 54,
  },
  {
    label: "55-64",
    min: 55,
    max: 64,
  },
  {
    label: "65+",
    min: 65,
    max: 120,
  },
];
const REVIEWER_TYPE = [
  {
    value: "all",
    label: "All",
  },
  {
    value: REVIEW_TYPE.REVIEW,
    label: "Verified Clients",
  },
  {
    value: REVIEW_TYPE.FIRST_IMPRESSION,
    label: "First impression from a user who is not yet a client",
  },
];

const useStyles = makeStyles((theme) => ({
  contentWrap: {
    padding: `${theme.spacing(4)}px ${theme.spacing(7)}px `,

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      margin: `0 ${theme.spacing(1)}px ${theme.spacing(4)}px`,
    },
  },
  keywordWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",

      "& > *": {
        margin: theme.spacing(1),
      },
    },
  },
  button: {
    minWidth: theme.spacing(15),

    [theme.breakpoints.down("md")]: {
      minWidth: theme.spacing(22),
    },
  },
  spacingBeforeServices: {
    marginTop: theme.spacing(4),
  },
  serviceTypes: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,

    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(2)}px 0`,
      textAlign: "left",
    },
  },
  dropdownLabel: {
    whiteSpace: "nowrap",
    marginRight: theme.spacing(2),

    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  activeFilters: {
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
  },
  activeFilter: {
    marginRight: theme.spacing(1) / 2,
    marginLeft: theme.spacing(1) / 2,
    marginBottom: theme.spacing(1),
  },
  servicesSelect: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    height: theme.spacing(4.5),
    "& .MuiChip-root": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
    },
    "& .MuiChip-label": {
      paddingRight: "4px",
    },
    "& .MuiSvgIcon-root": {
      height: "1rem",
      width: "1rem",
      marginLeft: 0,
      paddingLeft: 0,
      color: theme.palette.primary.main,
    },
    "& .MuiSelect-icon": {
      backgroundColor: "#fff",
    },
  },
  chipsBox: {
    display: "flex",
    flexWrap: "wrap",
  },
  dropdownHeight: {
    maxHeight: theme.spacing(39),
  },
  select: {
    width: "100%",
  },
}));

const INITIAL_FILTERS = {
  keyword: "",
  services: [],
  age: 0,
  reviewer_type: "all",
  changed: false,
};

const ReviewFilters = (props) => {
  const [state, setState] = useState({
    ...INITIAL_FILTERS,
  });

  const classes = useStyles();
  const { age, reviewer_type, services } = state;
  const {
    reviewFilters = [],
    showChipsOnly,
    firmOrNetworkType,
    type,
    tierFilters,
    handleTireFilterChange,
  } = props;
  const showFirstImpressions = reviewer_type === REVIEW_TYPE.FIRST_IMPRESSION;
  const [servicesSelectOpen, setServicesSelectOpen] = useState(false);
  useEffect(() => {
    const filters = sessionStorage.getItem(PERSISTED_FIRM_REVIEW_FILTERS);
    if (filters) {
      setState({ ...state, ...JSON.parse(filters).state?.filter });
    }
  }, []);

  useEffect(() => {
    if (state.changed) {
      handleFilterChange();
    }
  }, [state.age, state.services, state.reviewer_type]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;

    if (name === "reviewer_type" && value === REVIEW_TYPE.FIRST_IMPRESSION) {
      setState({ ...INITIAL_FILTERS, changed: true });
    }

    if (name === "reviewer_type") {
      submitAnalytics({ label: "Reviewer type: " + value  + " [selected]"});
    }

    if (name === "age") {
      handleAgeAnalytics(value, true);
    }

    setState({
      ...state,
      [name]: value,
      changed: true,
    });
  };

  const handleSelectService = (value) => {
    const selected = value.length > state.services.length;
    const changedValue = selected
      ? value.find((id) => !state.services.includes(id))
      : state.services.find((id) => !value.includes(id));
    const label = getServiceLabelBy("id", 5, changedValue);

    submitAnalytics({
      label: `services[]: ${label} [${selected ? "select" : "deselect"}]`,
    });

    setState({
      ...state,
      changed: true,
      services: selected
        ? [...state.services, changedValue]
        : state.services.filter((v) => v !== changedValue),
    });
    setServicesSelectOpen(false);
  };

  const handleDeleteService = (value) => {
    const label = getServiceLabelBy("id", 5, value);

    submitAnalytics({
      label: `services[]: ${label} [deselect]`,
    });

    setState({
      ...state,
      changed: true,
      services: state.services.filter((v) => v !== value),
    });
  };

  const handleUncheckService = (id, label) => (e) => {
    submitAnalytics({ label: `services[]: ${label} [deselect]` });

    setState({
      ...state,
      changed: true,
      services: state.services.filter((v) => v !== id),
    });
  };
  const handleDropdownReset = (name, value) => (e) => {
    if (name === "age") {
      handleAgeAnalytics(state.age, false);
    }

    if (name === "reviewer_type") {
      submitAnalytics({ label: "Reviewer type: " + value  + " [deselect]"});
    }

    setState({
      ...state,
      changed: true,
      [name]: value,
    });
  };
  const handleAgeAnalytics = (value, selected) => {
    const label = `Age: ${AGES[value].label} [${
      selected ? "select" : "deselect"
    }]`;

    submitAnalytics({ label });
  };
  const submitAnalytics = (options) => {
    const category =
      firmOrNetworkType == "firm" ? "Firm reviews" : "Network reviews";

    analytics.track("Filter", {
      category,
      ...options,
    });
  };
  const handleFilterChange = () => {
    const { services, age, reviewer_type } = state;
    const query = {};

    if (services.length) {
      query["services"] = services;
    }

    if (age) {
      query["age[min]"] = AGES[age].min;
      query["age[max]"] = AGES[age].max;
    }

    if (reviewer_type !== "all") {
      query.type = reviewer_type;
    }

    props.handleReviewFiltersUpdate(query, state, "filter");
  };

  return (
    <div>
      {!showChipsOnly &&
        (firmOrNetworkType == "network" ||
          !(
            firmOrNetworkType == "firm" && type == FIRM_TYPES.LEGAL_ADVISER
          )) && (
          <Paper className={classes.contentWrap}>
            <div className={classes.serviceTypes}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={8}>
                  <Typography variant="body1" className={classes.dropdownLabel}>
                    <b>Filter by service</b>
                  </Typography>
                  <Select
                    className={classes.select}
                    multiple
                    value={services}
                    open={servicesSelectOpen}
                    onClose={() => setServicesSelectOpen(false)}
                    onOpen={() => setServicesSelectOpen(true)}
                    onChange={(e) => handleSelectService(e.target.value)}
                    id="services"
                    classes={{
                      select: classes.servicesSelect,
                    }}
                    input={
                      <OutlinedInput
                        error={_.isEmpty(reviewFilters)}
                        labelWidth={0}
                      />
                    }
                    MenuProps={{
                      className: classes.dropdownHeight,
                    }}
                    renderValue={(selected) => (
                      <div className={classes.chipsBox}>
                        {selected.map((value) => {
                          return (
                            <SelectChip
                              key={value}
                              label={getServiceLabelBy("id", 5, value)}
                              value={value}
                              onDelete={() => handleDeleteService(value)}
                            />
                          );
                        })}
                      </div>
                    )}
                  >
                    {reviewFilters.map((id) => (
                      <MenuItem key={id} value={id}>
                        {getServiceLabelBy("id", 5, id)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <Typography variant="body1" className={classes.dropdownLabel}>
                    <b>Reviewer age</b>
                  </Typography>
                  <TextField
                    className={classes.select}
                    select
                    variant="outlined"
                    fullWidth
                    value={age}
                    name="age"
                    onChange={handleDropdownChange}
                    disabled={showFirstImpressions}
                  >
                    {AGES.map((v, i) => (
                      <MenuItem key={i} value={i}>
                        {v.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <Typography variant="body1" className={classes.dropdownLabel}>
                    <b>Reviewer type</b>
                  </Typography>
                  <TextField
                    className={classes.select}
                    select
                    variant="outlined"
                    fullWidth
                    value={reviewer_type}
                    name="reviewer_type"
                    onChange={handleDropdownChange}
                  >
                    {REVIEWER_TYPE.map((v) => (
                      <MenuItem key={v.value} value={v.value}>
                        {v.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </div>
          </Paper>
        )}
      <Hidden smDown>
        <div className={classes.activeFilters}>
          {services.map((id) => {
            const label = getServiceLabelBy("id", 5, id);

            return (
              <Chip
                label={label}
                onDelete={
                  showChipsOnly ? null : handleUncheckService(id, label)
                }
                className={classes.activeFilter}
                color="primary"
                key={id}
              />
            );
          })}
          {age > 0 && (
            <Chip
              label={AGES[age].label}
              onDelete={showChipsOnly ? null : handleDropdownReset("age", 0)}
              className={classes.activeFilter}
              color="primary"
            />
          )}
          {reviewer_type !== "all" && (
            <Chip
              label={REVIEWER_TYPE.find((v) => v.value === reviewer_type).label}
              onDelete={
                showChipsOnly
                  ? null
                  : handleDropdownReset("reviewer_type", "all")
              }
              className={classes.activeFilter}
              color="primary"
            />
          )}
          {tierFilters && tierFilters.length > 0 && (
            <>
              {tierFilters.map((tier) => (
                <Chip
                  label={`${tier}-star`}
                  onDelete={() =>
                    showChipsOnly ? null : handleTireFilterChange(tier)
                  }
                  className={classes.activeFilter}
                  color="primary"
                />
              ))}
            </>
          )}
        </div>
      </Hidden>
    </div>
  );
};

ReviewFilters.defaultProps = defaultProps;

export default ReviewFilters;
