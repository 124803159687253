import React, { useState, useEffect } from "react";
import _ from "lodash";
import ProfileClientReview from "../../../Profile/ProfileClientReview";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "../../../CustomButton";
import ProfileClientReviewsLabel from "../../../Profile/ProfileClientReviewsLabel";
import LoadingSpinner from "../../../LoadingSpinner";
import ProfileClientReviewsEmpty from "../../../Profile/ProfileClientReviewsEmpty";
import { makeStyles } from "@material-ui/core/styles";
import ReviewFilters, { stringifyQuery, parseQuery } from "./ReviewFilters";
import axios from "axios";
import ProfileReviewRatingTiers from "./ProfileReviewRatingTiers";
import {
  calculatePaginationOffset,
  calculatePaginationPagesCount,
  getCurrentPaginationPageFromLocation,
} from "../../../../utils/firmPageUtils";
import { useRouter } from "next/router";
import {
  API_KEYS,
  PERSISTED_FIRM_REVIEW_FILTERS,
} from "../../../../__constants__";
import ReviewPagination from "../../../ReviewPagination/ReviewPagination";
import * as analytics from "../../../../analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    "& .MuiPaginationItem-root": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      "&.Mui-selected": {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary.main,
      },
      "&:hover": {
        backgroundColor: theme.palette.tertiary.dark,
      },
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
    },
  },
  root: {
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(4),

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },

    [theme.breakpoints.down("sm")]: {
      margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },
  loadingWrapper: {
    color: theme.palette.primary.contrastText,
  },
  actions: {
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      margin: `0 ${theme.spacing(2)}px`,
    },
  },
  actionButtonFirst: {
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  actionButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  reviewRatingTiersAndFiltersWrap: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "1rem",
    marginBottom: theme.spacing(6),
    padding: `${theme.spacing(4)}px ${theme.spacing(7)}px `,

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      margin: `0 ${theme.spacing(1)}px ${theme.spacing(4)}px`,
    },
  },
  filters: {
    "& .MuiPaper-root": {
      padding: 0,
      boxShadow: "none",
    },
  },
  divider: {
    width: "1px",
    background: theme.palette.tertiary.dark,
    margin: "0 6px",
  },
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      textAlign: 'center',
      fontSize: '1.3rem'
    },
  },
}));
export const FIRM_PAGINATION_REVIEWS_PER_PAGE = 20;

const INITIAL_STATE = {
  reviews: [],
  loading: true,
  error: false,
  totalReviewCount: 0,
};

const emptyTiers = [{
  count: 0,
  tier: 5
},
{
  count: 0,
  tier: 4
},{
  count: 0,
  tier: 3
},{
  count: 0,
  tier: 2
},{
  count: 0,
  tier: 1
},];

const fetchReviewData = async (url) => {
  const { data } = await axios.get(url);

  return {
    reviews: _.get(data, "data", []),
    totalReviewCount: _.get(data, "aggregate_data.total", 0),
  };
};

const generateReviewsApiEndpointUrl = (
  id,
  reviewFiltersQuery,
  firmOrNetworkType,
  router
) => {
  const offset = calculatePaginationOffset(router.query);

  return `${API_KEYS.API_URL}/v2/public/${firmOrNetworkType}/${id}/reviews?limit=${FIRM_PAGINATION_REVIEWS_PER_PAGE}&offset=${offset}&${reviewFiltersQuery}`;
};

const useFetchReviews = (id, router, initialReviews, firmOrNetworkType, initialPage) => {
  const [state, setState] = useState({ ...INITIAL_STATE });
  const [reviewFiltersQueryString, setReviewFiltersQueryString] = useState(
    () => {
      if (typeof window !== "undefined") {
        const reviewFilters = sessionStorage.getItem(
          PERSISTED_FIRM_REVIEW_FILTERS
        );
        return reviewFilters ? JSON.parse(reviewFilters).query : "";
      } else {
        return "";
      }
    }
  );

  const url = generateReviewsApiEndpointUrl(
    id,
    reviewFiltersQueryString,
    firmOrNetworkType,
    router
  );

  useEffect(() => {
    (async () => {
      setState({ ...state, loading: true });
      try {
        const pageFromRouter = router.query?.page ? router.query?.page : 1;
        if (reviewFiltersQueryString || !initialReviews || (pageFromRouter !== initialPage)) {
          const { reviews, totalReviewCount } = await fetchReviewData(url);
          setState({
            ...INITIAL_STATE,
            reviews: reviews,
            totalReviewCount: totalReviewCount,
            loading: false,
          });
        } else {
          setState({
            ...INITIAL_STATE,
            reviews: initialReviews.data,
            totalReviewCount: initialReviews.aggregate_data.total,
            loading: false,
          });
        }
      } catch {
        setState({
          ...INITIAL_STATE,
          loading: false,
          error: true,
        });
      }
    })();
  }, [id, reviewFiltersQueryString, url]);

  return {
    reviews: state.reviews,
    loading: state.loading,
    totalReviewCount: state.totalReviewCount,
    error: state.error,
    reviewFiltersQueryString: reviewFiltersQueryString,
    setReviewFiltersQueryString: setReviewFiltersQueryString,
  };
};

const ReviewsList = (props) => {
  const {
    initialReviews,
    profile,
    profileName,
    reviewFilters,
    onPaginationChange,
    firmOrNetworkType,
    initialPage,
    unclaimed
  } = props;

  const classes = useStyles();
  const profileReviewCount = _.get(profile, ["review_count"], null);
  const hasReviewsFromOtherFirms = _.get(
    profile,
    "professional_key_attributes.reviews_from_other_firm",
    false
  );
  const reviewRatingTiers = unclaimed ? emptyTiers : _.get(
    profile,
    ["statistics", "review_rating_tiers"],
    emptyTiers
  );
  const reviewCount = _.get(profile, ["statistics", "review_count"], 0);
  const router = useRouter();

  const {
    reviews,
    totalReviewCount,
    loading,
    error,
    reviewFiltersQueryString,
    setReviewFiltersQueryString,
  } = useFetchReviews(profile.id, router, initialReviews, firmOrNetworkType, initialPage);

  const currentPage = getCurrentPaginationPageFromLocation(router);
  const pagesCount = calculatePaginationPagesCount(totalReviewCount);
  const [tierFilters, setTierFilters] = useState([]);

  useEffect(() => {
    if (firmOrNetworkType === "firm") {
      const filters = sessionStorage.getItem(PERSISTED_FIRM_REVIEW_FILTERS)
        ? JSON.parse(sessionStorage.getItem(PERSISTED_FIRM_REVIEW_FILTERS))
        : null;
      if (filters && filters.state?.tierFilter) {
        setTierFilters(filters.state?.tierFilter);
      }
    }
  }, []);

  if (error) {
    router.push("/404");
  }

  if (!loading && totalReviewCount > 0 && currentPage > pagesCount) {
    router.push("/404");
  }

  if (!loading && totalReviewCount === 0 && currentPage !== 1) {
    router.push("/404");
  }

  const handleTireFilterChange = (tier) => {
    let newTierFilters = tierFilters;
    const index = newTierFilters.indexOf(tier);
    if (index !== -1) {
      newTierFilters.splice(index, 1);
      analytics.track(`Tier: ${tier} [deselect]`, {
        category: firmOrNetworkType === "firm" ? "Firm Profile" : "Network Profile",
      });
    } else {
      newTierFilters = [...newTierFilters, tier];
      analytics.track(`Tier: ${tier} [selected]`, {
        category: firmOrNetworkType === "firm" ? "Firm Profile" : "Network Profile",
      });
    }
    setTierFilters([...newTierFilters]);
    const query = {};
    if (newTierFilters.length) {
      query["tiers"] = newTierFilters;
    }

    handleReviewFiltersUpdate(query, newTierFilters, "tierFilter");
  };

  const renderReview = (review, i) => {
    return (
      <ProfileClientReview
        key={i}
        review={review}
        type={review.reviewer_type}
        professionalFirstName={review.professional.first_name}
        professionalLastName={review.professional.last_name}
        isFirmReview={true}
      />
    );
  };

  const handleReviewFiltersUpdate = (filtersQuery, componentState, type) => {
    const filtersFromSessionStorage = sessionStorage.getItem(
      PERSISTED_FIRM_REVIEW_FILTERS
    )
      ? JSON.parse(sessionStorage.getItem(PERSISTED_FIRM_REVIEW_FILTERS))
      : {
          query: {},
          state: {},
          queryParts: {},
        };

    const finalQueryArray = filtersFromSessionStorage.queryParts;
    finalQueryArray[type] = filtersQuery;

    const stateToSaveToStorage = {
      ...filtersFromSessionStorage.state,
      [type]: componentState,
    };
    const queryPartsToSaveToStorage = {
      ...filtersFromSessionStorage.queryParts,
      [type]: filtersQuery,
    };

    let finalQuery = {};
    Object.keys(finalQueryArray).map((key) => {
      finalQuery = { ...finalQuery, ...finalQueryArray[key] };
      return true;
    });

    const queryString = stringifyQuery(finalQuery);

    if (
      queryString &&
      queryString !== reviewFiltersQueryString &&
      router.query?.page &&
      router.query?.page > 1
    ) {
      const { page, ...routerQuery } = router.query;
      router.push(
        {
          query: { ...routerQuery },
        },
        undefined,
        { shallow: true }
      );
      onPaginationChange();
    }

    if (queryString !== reviewFiltersQueryString) {
      setReviewFiltersQueryString(queryString);
      sessionStorage.setItem(
        PERSISTED_FIRM_REVIEW_FILTERS,
        JSON.stringify({
          query: stringifyQuery(finalQuery),
          state: stateToSaveToStorage,
          queryParts: queryPartsToSaveToStorage,
        })
      );
    }
  };

  const renderReviewList = () => {
    return (
      <>
        {_.isEmpty(reviews) ? (
          <Paper className={classes.root}>
            <Typography>No reviews matching your filter selections.</Typography>
          </Paper>
        ) : (
          reviews.map((review, i) => renderReview(review, i))
        )}
        {pagesCount > 1 && (
          <div className={classes.actions}>
            <ReviewPagination
              baseUrl={`/${firmOrNetworkType}/${router.query.firmPermalink}`}
              pagesCount={pagesCount}
              onChange={onPaginationChange}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <section className={classes.container} id="gta-reviews-list">
      <Typography variant="h5" component="h5" className={classes.title}>
        Reviews
      </Typography>
      {hasReviewsFromOtherFirms && !unclaimed && <ProfileClientReviewsLabel />}
      {props.children}
      <Paper className={classes.reviewRatingTiersAndFiltersWrap}>
        <div>
          <ProfileReviewRatingTiers
            ratings={reviewRatingTiers}
            reviewCount={reviewCount}
            tierFilters={tierFilters}
            handleTireFilterChange={handleTireFilterChange}
            filterTiers={true}
          />
        </div>
        { reviewCount > 0 && !unclaimed ?
        <div className={classes.filters}>
          <ReviewFilters
            handleReviewFiltersUpdate={handleReviewFiltersUpdate}
            id={profile.id}
            reviewFilters={reviewFilters}
            type={profile.type}
            tierFilters={tierFilters}
            firmOrNetworkType={firmOrNetworkType}
            handleTireFilterChange={handleTireFilterChange}
          />
        </div> : null}
      </Paper>

      {loading ? (
        <LoadingSpinner className={classes.loadingWrapper} color="inherit" />
      ) : (0 < profileReviewCount || totalReviewCount) && !unclaimed ? (
        renderReviewList()
      ) : (
        <ProfileClientReviewsEmpty name={profileName} />
      )}
    </section>
  );
};

export default ReviewsList;
