import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import LocationOn from '@material-ui/icons/LocationOn';
import {getFirmChangedFirmReviewMessage, REVIEW_TYPE} from '../../__constants__';
import { generateRatingLabels, getReviewTypeTitle } from '../../data/attributeHelper';

import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StarRatingDisplay from "../StarRatingDisplay";

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(4),
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),

        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },

        [theme.breakpoints.down('sm')]: {
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
        },
    },
    mainContainer: {
        marginTop: 0,
        marginBottom: 0,

        '& > $innerCol': {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),

            [theme.breakpoints.down('md')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
            },
        },
    },
    innerCol: {
        '&:first-child': {
            borderRight: `1px solid ${theme.palette.grey[300]}`,
        },

        [theme.breakpoints.down('md')]: {
            '&:first-child': {
                borderRight: 0,
            },
        },
    },
    title: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1),
        },
    },
    locationContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.primary.main,
        paddingRight: theme.spacing(1),
    },
    reviewRatings: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),

        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            marginBottom: theme.spacing(2),
        },
    },
    firstImpressionRatings: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),

        [theme.breakpoints.down('md')]: {
            margin: 0,
        },
    },
    rating: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        marginBottom: theme.spacing(0.75),
    },
    starsWrapper: {
        marginRight: theme.spacing(2),
    },
    professionalResponseTitle: {
        color: theme.palette.primary.main,
    },
    responseBody: {
        whiteSpace: 'pre-line',
    },
    borderSecondary: {
        border: `1px solid ${theme.palette.secondary.main}`,
        padding: `${theme.spacing(1)}px`,
    },
    reviewMessage:{
        fontSize: 12,
    },
    overall: {
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.tertiary.contrastText}`
    }
});

class ProfileClientReview extends Component {
    renderRatings = review => {
        const { classes } = this.props;
        const isReviewType = review.reviewer_type === 'review';

        return (
            <div
                className={(
                    review.reviewer_type === REVIEW_TYPE.FIRST_IMPRESSION
                    ? classes.firstImpressionRatings
                    : classes.reviewRatings
                )}
            >
                <div className={`${classes.rating} ${classes.overall}`}>
                    <div className={classes.starsWrapper}>
                        <StarRatingDisplay rating={Math.round(review.average_score)} />
                    </div>
                    <Typography variant="body1">
                        <b>Overall</b>
                    </Typography>
                </div>
                {
                    isReviewType && review.review_scores &&
                    Object.entries(review.review_scores).map(([key, value]) =>
                        <div className={classes.rating} key={key}>
                            <div className={classes.starsWrapper}>
                                <StarRatingDisplay
                                    rating={Math.round(value)}
                                />
                            </div>
                            <Typography variant="body2">
                                { generateRatingLabels(key) }
                            </Typography>
                        </div>
                    )
                }
            </div>
        );
    };

    renderReview = (question, answer, professionalFirstName, repeatReview, gka) => {
        const { classes } = this.props;
        return (
            <Fragment key={question}>
                {
                    repeatReview && gka == 'how_did_help_you_since_last_review' &&
                    <Typography
                        className={classes.borderSecondary}
                        variant="body2" color="secondary"
                        paragraph={true}
                    >
                        <b>This review is a follow-up review from a client who left a review previously.</b>
                    </Typography>
                }
                <Typography variant="body2">
                    <b>{ question.replace('{{first_name}}', professionalFirstName) }</b>
                </Typography>
                <Typography variant="body1" paragraph className={this.props.classes.responseBody}>
                    { answer }
                </Typography>
            </Fragment>
        );
    }

    renderReviewContent = (review, professionalFirstName) => {
        const questions = _.get(review, 'questions', null);

        const professionalResponse = _.get(review, 'professional_response', null);
        const repeatReview = _.get(review, 'repeat_review', null);

        if (!questions) return null;

        const questionsList = questions.map(question => {
            return this.renderReview(question.label_template, question.answer, professionalFirstName, repeatReview, question.question.gka)
        });

        return (
            <Fragment>
                { questionsList }
                {
                    professionalResponse &&
                    <Fragment>
                        <Typography
                            variant="body2"
                            className={this.props.classes.professionalResponseTitle}
                        >
                            <b>{ `${professionalFirstName}'s` } reply</b>
                        </Typography>
                        <Typography variant="body1" paragraph className={this.props.classes.responseBody}>
                            { professionalResponse }
                        </Typography>
                    </Fragment>
                }
            </Fragment>
        );
    };

    reviewMessage = (review, isFirmReview) => {
        if (isFirmReview && review.firm && review.firm.id !== review.professional.firm_id) {
                return getFirmChangedFirmReviewMessage(review.firm.type);
        }else {
            return '';
        }
    };

    renderIsFromPreviousFirmMessage = (review, classes, isFirmReview) => {
        if (review.is_from_previous_firm && !isFirmReview) {
            return (
                <Typography className={classes.reviewMessage} variant="body2" color="error">
                    This review was gathered whilst the adviser was at a different firm.
                </Typography>
            );
        }

        return null;
    }

    render() {
        const { classes, review, type, professionalFirstName, professionalLastName, isFirmReview } = this.props;
        const isFirstImpression = type === REVIEW_TYPE.FIRST_IMPRESSION;

        return (
            <Paper className={classes.root} id={`review${review.id}`}>
                <Grid container spacing={4} className={classes.mainContainer}>
                    <Grid item xs={12} lg={4} className={classes.innerCol}>
                        <Typography
                            paragraph
                            variant="h6"
                            color="primary"
                            className={classes.title}
                        >
                            {getReviewTypeTitle(review.repeat_review, isFirstImpression, isFirmReview, professionalFirstName, professionalLastName)}
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {moment(review.submitted_at).format('MMMM, YYYY')}
                            <Typography className={classes.reviewMessage} variant="body2" color="error">
                                { this.reviewMessage( review, isFirmReview ) }
                            </Typography>
                            { this.renderIsFromPreviousFirmMessage(review, classes, isFirmReview) }
                        </Typography>
                        {this.renderRatings(review)}
                        {
                            !isFirstImpression &&
                            <div className={classes.locationContainer}>
                                <LocationOn className={classes.icon} />
                                <Typography variant="body1">
                                    Based in { review.location }
                                </Typography>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} lg={8} className={classes.innerCol}>
                        {
                            this.renderReviewContent(review, professionalFirstName)
                        }
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(ProfileClientReview);
