import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    chip: {
        borderRadius: theme.spacing(1),
        margin: theme.spacing(1) / 4
    },
    chipIcon: {
        boxSizing: 'unset',
        padding: theme.spacing(1) / 2,
        borderLeft: `2px solid rgba(255, 255, 255, 0.7)`,
        marginLeft: theme.spacing(1),
    },
}));

const SelectChip = (props) => {
    const classes = useStyles();

    return (
        <Chip
            label={props.label}
            color="primary"
            value={props.value}
            className={classes.chip}
            deleteIcon={<Close className={classes.chipIcon} onMouseDown={props.onDelete}/>}
            onDelete={() => {}}
        />
    );
};

export default SelectChip;
