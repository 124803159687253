import React from 'react';
import { adviserProfileFirmReviewMessage } from '../../__constants__';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    note: {
        color: theme.palette.tertiary.main,
        fontSize: 16,
        fontWeight: 'bold'
    },
    infoIcon: {
        color: theme.palette.tertiary.main,
        cursor: 'pointer',
        paddingRight: theme.spacing(1),
    },
    flex: {
        display: 'flex',
    },
}));

const ProfileClientReviewsLabel = () => {
    const classes = useStyles();

    return (
        <div className={classes.flex}>
            <InfoIcon className={classes.infoIcon} />
            <Typography variant="body2" paragraph className={classes.note}>
                {adviserProfileFirmReviewMessage()}
            </Typography>
        </div>
    );
};

export default ProfileClientReviewsLabel;
