import React from "react";
import { makeStyles, Typography, Hidden } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: "100%",
  },
  ratings: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginTop: theme.spacing(3),
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    "& .MuiSvgIcon-root": {
      height: "30px",
      width: "30px",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
  },
  rowHover: {
    "&:hover": {
      "& .MuiCheckbox-root": {
        backgroundColor: "rgba(2, 45, 79, 0.04)",
      },
      "& .bar": {
        background: theme.palette.tertiary.main,
      },
      "& .tier-label .MuiTypography-root, .count-label .MuiTypography-root": {
        color: theme.palette.tertiary.main,
      },
    },
  },
  checked: {
    "& .MuiCheckbox-root": {
      backgroundColor: "rgba(2, 45, 79, 0.04)",
    },
    "& .bar": {
      background: theme.palette.tertiary.main,
    },
    "& .tier-label .MuiTypography-root, .count-label .MuiTypography-root": {
      color: theme.palette.tertiary.main,
    },
    '& .Mui-checked': {
      color: theme.palette.tertiary.main,
    }
  },
  tierLabel: {
    textAlign: "right",
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    "& .MuiTypography-root": {
      fontSize: "1.3rem",
      fontWeight: 700,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
  },
  barWrapper: {
    flex: 1,
    width: "100%",
    background: theme.palette.tertiary.contrastText,
    borderRadius: 3,
    "& > div": {
      height: "28px",
    },
  },
  countLabel: {
    minWidth: theme.spacing(10),
    marginLeft: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.primary.main,
    "& .MuiTypography-root": {
      fontSize: "1.3rem",
      fontWeight: 700,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
  },
  bar: {
    width: "0%",
    height: theme.spacing(2),
    borderRadius: 2,
    background: theme.palette.primary.main,
  },
  disabled: {
    "& .tier-label .MuiTypography-root": {
      color: theme.palette.tertiary.dark,
    },
    "& .count-label .MuiTypography-root": {
      color: theme.palette.tertiary.dark,
    },
  },
}));

const ProfileReviewRatingTiers = ({
  reviewCount,
  ratings = [],
  filterTiers = true,
  tierFilters,
  handleTireFilterChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <div className={`${classes.ratings} ratings-wrapper`}>
        {ratings.map((rating) => {
          const percentage = reviewCount === 0 ? 0 : Math.ceil((rating.count / reviewCount) * 100);
          if (percentage > 100) {
            percentage = 100
          }
          return (
            <>
              {filterTiers ? (
                <div
                  key={rating.tier}
                  className={`${classes.row} ${classes.rowHover} ${
                    percentage === 0 ? classes.disabled : ""
                  } ${
                    tierFilters.includes(rating.tier) ? classes.checked : ""
                  }`}
                >
                  <div className={`${classes.tierLabel} tier-label`}>
                    <Typography variant="body2" color="inherit">
                      {`${rating.tier}-star`}
                    </Typography>
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={percentage === 0}
                        checked={tierFilters.includes(rating.tier) || false}
                        onChange={(e) => handleTireFilterChange(e.target.value)}
                        color="primary"
                        name="tierFilter"
                        value={rating.tier}
                      />
                    }
                    className={classes.fromControlLabel}
                  />

                  <div
                    className={`${classes.barWrapper} bar-wrapper`}
                    onClick={() =>
                      percentage === 0
                        ? null
                        : handleTireFilterChange(rating.tier)
                    }
                  >
                    <div
                      style={{ width: `${percentage}%` }}
                      className={`${classes.bar} bar`}
                    />
                  </div>
                  <div className={`${classes.countLabel} count-label`}>
                    <Typography variant="body2" color="inherit">
                      {rating.count.toLocaleString("en-UK")}
                    </Typography>
                  </div>
                </div>
              ) : (
                <>
                  <div key={rating.tier} className={`${classes.row}`}>
                    <div className={`${classes.tierLabel} tier-label`}>
                      <Typography variant="body2" color="inherit">
                        {`${rating.tier}-star`}
                      </Typography>
                    </div>
                    <div className={`${classes.barWrapper} bar-wrapper`}>
                      <div
                        style={{ width: `${percentage}%` }}
                        className={`${classes.bar} bar`}
                      />
                    </div>
                    <div className={`${classes.countLabel} count-label`}>
                      <Typography variant="body2" color="inherit">
                        {rating.count.toLocaleString("en-UK")}
                      </Typography>
                    </div>
                  </div>
                </>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileReviewRatingTiers;
