import { FIRM_PAGINATION_REVIEWS_PER_PAGE } from '../components/Firm/components/Reviews/ReviewsList';
import _ from 'lodash';

export const getFirmIdFromUrl = (firmPermalink = null) => {
    return firmPermalink ? firmPermalink.split('-')[0] : '';
};

export const calculatePaginationOffset = (query) => {
    const page = query.page ? query.page : 1
    if (page <= 1) {
        return 0;
    }

    return (page - 1) * FIRM_PAGINATION_REVIEWS_PER_PAGE;
};

export const calculatePaginationPagesCount = (totalReviewCount = 0) => {
    if (totalReviewCount === 0) {
        return 0;
    }

    return Math.ceil(totalReviewCount / FIRM_PAGINATION_REVIEWS_PER_PAGE);
};

export const getCurrentPaginationPageFromLocation = (router) => {
    return parseInt(router.query.page || '1', 10);
};

export const getFirmProfileData = (profile) => {
    return {
        logoUrl: _.get(profile, "logo.urls.standard_retina", false),
        reviewAverage: _.get(profile, ["statistics", "review_average"], 0),
        reviewCount: _.get(profile, ["statistics", "review_count"], 0),
        verifiedCount: _.get(profile, ["statistics", "verified_advisers"], 0),
        singularFirmType: _.get(profile, ["titles", "singular"]),
        pluralFirmType: _.get(profile, ["titles", "plural"]),
        isTopRatedFirm: _.get(profile, "is_top_rated_firm", false),
        name: _.get(profile, "name", ""),
        about: _.get(profile, "about", ""),
        url: _.get(profile, "url", ""),
        addressLine1: _.get(profile, ["firm_address", "address_line1"], ""),
        town: _.get(profile, ["firm_address", "town"], ""),
        postcode: _.get(profile, ["firm_address", "postcode"], ""),
    }
}
