import {
    FINANCIAL_ADVISER_ID,
    ACCOUNTANT_ID,
    LEGAL_ADVISER_ID,
    URL_COMPONENTS,
    PROFESSIONAL_TYPE,
} from '../__constants__';
import { getServiceLabelBy } from './attributeHelper';
import { formatFirstCharUpper } from '../utils/utilities';
import { getCurrentPaginationPageFromLocation } from '../utils/firmPageUtils';

export function getProfileMetaTitle(profileData) {
    if (!profileData) return '';

    const { full_name, vertical_id, address: { town }, professional_type } = profileData;
    let vertical = getFriendlyVerticalName(vertical_id);

    if ([PROFESSIONAL_TYPE.PROTECTION_LIMITED, PROFESSIONAL_TYPE.PROTECTION_WHOLE_OF_MARKET].includes(professional_type)) {
        vertical = 'Protection Adviser';
    }

    if ([PROFESSIONAL_TYPE.FINANCIAL_COACH].includes(professional_type)) {
        vertical = 'Financial Coach';
    }

    return `${full_name}, ${vertical} in ${town} - Read Client Reviews`;
}

export function getProfileMetaKeywords(profileData, services = []) {
    if (!profileData) return '';

    let keywords = [];
    const { full_name, firm_name, vertical_id, address: { town } } = profileData;

    switch (vertical_id) {
        case FINANCIAL_ADVISER_ID:
            keywords.push(`Independent Financial Adviser in ${town}, ${town} IFA, ${town} Independent Financial Advisor, Services`);
            break;
        case ACCOUNTANT_ID:
            keywords.push(`Accountant in ${town}, ${town} Accountant`);
            break;
        case LEGAL_ADVISER_ID:
            keywords.push(`Solicitor in ${town}, ${town} Lawyer`);
            break;
        default:
    }

    keywords.push(full_name, firm_name, town);

    if (services.length) {
        services.forEach(service => keywords.push(getServiceLabelBy('id', vertical_id, service)));
    }

    return keywords.join(', ')
}

export function getAdviserFirmMetaKeywords(services, titles) {
    if (!services) return '';

    let keywords = [];

    switch (titles.singular) {
        case 'financial coach':
            keywords.push('Financial Coach');
            break;
        case 'adviser':
        default:
            keywords.push('Financial Adviser, Financial Advisor');
            break;
    }

    if (services.length) {
        services.forEach(service => keywords.push(service.label));
    }

    return keywords.join(', ')
}

export function getProfileMetaDescription(profileData) {
    if (!profileData) return '';

    const { full_name, vertical_id, firm_name, address: { town } } = profileData;
    const vertical = getFriendlyVerticalName(vertical_id);

    return `Read what clients have written about ${full_name} of ${firm_name} - One of our ${vertical}s in ${town}`;
}

export function getProfileMetaCanonical(profileData) {
    if (process.browser) {
        return profileData ? `${window.location.origin}/${profileData.permalink.url}` : null;
    }
}

export function hasProfileNoIndexNoFollow(profileData) {
    if (!profileData) {
        return true;
    }

    if (!profileData.activated || !profileData.profile_about_complete) {
        return true;
    }

    return false;
}

export function hasFirmProfileNoIndexNoFollow(firmProfileData, firmOrNetworkType) {
    if (!firmProfileData) {
        return true;
    }

    if (firmOrNetworkType === 'firm' && !firmProfileData.is_firm_profile_index) {
        return true;
    }


    return false;
}

export function getFriendlyVerticalName(verticalId) {
    switch (verticalId) {
        case FINANCIAL_ADVISER_ID:
            return 'Financial Adviser';
        case ACCOUNTANT_ID:
            return 'Accountant';
        case LEGAL_ADVISER_ID:
            return 'Solicitor';
        default:
            return '';
    }
}

export function getSearchMetaTitle(searchData) {
    const { location, urlPath, showMe } = searchData;
    const town = formatFirstCharUpper(location);

    switch (urlPath) {
        case URL_COMPONENTS.SOLICITOR:
            return `The top 5 rated and reviewed Legal Advisers in ${town} on VouchedFor`;
        case URL_COMPONENTS.ACCOUNTANT:
            return `The top 5 rated and reviewed Accountants in ${town} on VouchedFor`;
        case URL_COMPONENTS.FA_MA_CANONICAL:
        default:
            if (showMe === 'firms') {
                return `The top 5 rated and reviewed Financial Advice Firms and Mortgage Firms in ${town}`;
            }

            return `The top 5 rated and reviewed Financial Advisers & IFAs in ${town}`;
    }
}

export function getSearchMetaDescription(searchData) {
    const { location, urlPath, showMe } = searchData;
    const town = formatFirstCharUpper(location);

    switch (urlPath) {
        case URL_COMPONENTS.SOLICITOR:
            return `Find 5* rated Legal Advisers in ${town}. Read reviews from 1000s of Solicitor clients. ` +
                `We're the UK's largest review site for Legal Advisers. Use our easy search tool to find the best Legal Advisers in ${town}. ` +
                `96% positive customer feedback.`;
        case URL_COMPONENTS.ACCOUNTANT:
            return `Find 5* rated Accountants in ${town}. Read reviews from 1000s of Accountant clients. ` +
                `We're the UK's largest review site for Accountants. Use our easy search tool to find the best Accountants in ${town}. ` +
                `96% positive customer feedback.`;
        case URL_COMPONENTS.FA_MA_CANONICAL:
        default:
            if (showMe === 'firms') {
                return `Find top 5 rated and reviewed Financial Advice Firms and Mortgage Firms in ${town}. Read reviews from 1000s of Financial Advisor and Mortgage Advisor clients. ` +
                    `We're the UK's largest review site for Independent Financial Advisors (IFAs) and other Financial Advisors. Use our easy search tool to find the best Financial Advice Firms and Mortgage Firms in ${town}. ` +
                    `96% positive customer feedback.`;
            }

            return `Find 5* rated Independent Financial Advisors and Mortgage Advisors in ${town}. Read reviews from 1000s of Financial Advisor and Mortgage Advisor clients. ` +
                `We're the UK's largest review site for Independent Financial Advisors (IFAs) and other Financial Advisors. Use our easy search tool to find the best Financial Advisors and Mortgage Advisors in ${town}. ` +
                `96% positive customer feedback.`;
    }
}

export function getSearchMetaKeywords(searchData) {
    const { location, urlPath, showMe } = searchData;
    const town = formatFirstCharUpper(location);

    switch (urlPath) {
        case URL_COMPONENTS.SOLICITOR:
            return `Solicitors in ${town}, ${town} Lawyers`;
        case URL_COMPONENTS.ACCOUNTANT:
            return `Accountants in ${town}, ${town} Accountants`;
        case URL_COMPONENTS.FA_MA_CANONICAL:
        default:
            if (showMe === 'firms') {
                return `Financial Advice Firms in ${town}, ${town} Financial Advice Firms, Mortgage Firms in ${town}, ${town} Mortgage Firms`;
            }

            return `Independent Financial Advisers in ${town}, ${town} IFA, ${town} Independent Financial Advisers, Mortgage Advisers in ${town}, ${town} Mortgage Advisers`;
    }
}

export function getSearchPageCanonical(searchData, router) {
    const { urlPath, showMe } = searchData;

    const EXCLUDED_CANONICAL_URLS = [
        '/financial-advisor-ifa/snowshill',
        '/mortgage-advisor/snowshill',
        '/financial-advisor-ifa/ombersley',
        '/mortgage-advisor/ombersley',
        '/financial-advisor-ifa/muker',
        '/mortgage-advisor/muker',
        '/financial-advisor-ifa/grasmere',
        '/mortgage-advisor/grasmere',
        '/financial-advisor-ifa/beesands',
        '/mortgage-advisor/beesands',
    ];

    // This is for an experiment (VFR-902)
    if (EXCLUDED_CANONICAL_URLS.includes(router.asPath)) {
        return null;
    }

    if (process.browser) {
        switch (urlPath) {
            case URL_COMPONENTS.FA_MA_CANONICAL:
                if (showMe === 'advisers') {
                    return window.location.href.split('?')[0].replace(urlPath, URL_COMPONENTS.FA_MA_CANONICAL);
                }

                return window.location.href.replace(urlPath, URL_COMPONENTS.FA_MA_CANONICAL);
            default:
                return window.location.href;
        }
    }

}

export function getSearchPageMetaData(searchData, router) {
    if (!searchData) return {};

    return {
        title: getSearchMetaTitle(searchData),
        keywords: getSearchMetaKeywords(searchData),
        desc: getSearchMetaDescription(searchData),
        canonical: getSearchPageCanonical(searchData, router)
    };
}

export function getProfessionalMetaData(type) {
    switch (type) {
        case URL_COMPONENTS.SOLICITOR:
            return {
                title: `Find Top-Rated Solicitors`,
                desc: `Search 100s of top-rated Solicitors nationwide. Read 1000s of reviews from genuine clients.`,
            };
        case URL_COMPONENTS.ACCOUNTANT:
            return {
                title: `Find Top-Rated Accountants`,
                desc: `Search 100s of top-rated Accountants nationwide. Read 1000s of reviews from genuine clients.`,
            };
        case URL_COMPONENTS.FA_MA_CANONICAL:
        default:
            return {
                title: `Find Top-Rated Financial and Mortgage Advisers`,
                desc: `Search 1000s of top-rated Financial and Mortgage Advisers. Over 80,000 reviews from genuine clients.`,
            };
    }
}

export function getWidgetReviewsMetaData(profileData, totalCount) {
    if (!profileData) return {};

    const { full_name, firm_name } = profileData;

    if (process.browser) {
        return {
            title: `Read ${totalCount} Client Reviews - ${full_name}, ${firm_name}`,
            desc: `Read what ${totalCount} clients have written about ${full_name}, ${firm_name}`,
            canonical: window.location.origin + window.location.pathname.replace('/reviews', '')
        };
    }
}

export function getFirmMetaCanonical(firmData, router) {
    const page = getCurrentPaginationPageFromLocation(router);

    if (!firmData) {
        return null;
    }

    if (process.browser) {
        return page === 1 ? `${window.location.origin}/${firmData.url}` : `${window.location.origin}/${firmData.url}?page=${page}`;
    }
}

const getFirmType = (firmTitle) => {
    return firmTitle === 'financial coach' ? 'Financial Coaches' : 'Financial Advisers';
}

export function getFirmPageMetaData(firmData, services, router, firmOrNetworkType) {

    if (!firmData) {
        return null;
    }

    const firmType = getFirmType(firmData.titles.singular)

    return {
        keywords: getAdviserFirmMetaKeywords(services, firmData.titles),
        title: firmOrNetworkType == 'network' ? `${firmData.name} Reviews` : `${firmData.name}, ${firmType} - Read Client Reviews`,
        desc: `Read what ${firmData.statistics.review_count} clients have written about ${firmData.name} - One of our Financial ${firmData.titles.singular === 'financial coach' ? 'Coach' : 'Advice'} Firms`,
        canonical: getFirmMetaCanonical(firmData, router),
    };
}

export function getFirmPageTitle(firmData) {

    if (!firmData) {
        return null;
    }

    const firmType = getFirmType(firmData.titles.singular)

    return `${firmData.name}, ${firmType} - Read Client Reviews`
}


export function getNetworkPageTitle(networkData) {

    if (!networkData) {
        return null;
    }

    return `${networkData.name} Reviews`
}

export function getRegionPageMetaCanonical(regionInformation, regionWithHyphen) {
    if (process.browser) {
        regionInformation[2] = regionWithHyphen;
        return (window.location.origin + regionInformation.join("/"));
    }
}
