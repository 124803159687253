import React from 'react';
import Link from 'next/link'
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from '@material-ui/core';
import { getCurrentPaginationPageFromLocation } from '../../utils/firmPageUtils';
import { useRouter } from 'next/router';


const useStyles = makeStyles(theme => ({
    pagination: {
        '& .MuiPagination-ul': {
            justifyContent: 'center',
        },
        '& .MuiPaginationItem-root': {
            color: theme.palette.primary.contrastText,
        },
    },
}));

const ReviewPagination = ({ baseUrl, pagesCount, onChange = () => {} }) => {
    const classes = useStyles();
    const router = useRouter()
    const currentPage = getCurrentPaginationPageFromLocation(router);
    return (
        <>
            <Pagination
                page={currentPage}
                className={classes.pagination}
                count={pagesCount}
                onChange={onChange}
                renderItem={(item) => (
                    <Link href={item.page === 1 ? baseUrl : `${baseUrl}?page=${item.page}`} shallow passHref>
                        <PaginationItem
                            {...item}
                        />
                    </Link>
                )}
            />
        </>
    );
};

export default ReviewPagination;
